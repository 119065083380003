import { Amplify, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { USER_POOL_ID, USER_POOL_CLIENT_ID } from '../constants/common';

Amplify.configure({
  Auth: {
    region: 'ca-central-1',
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_CLIENT_ID,

    oauth: {
      domain: 'auth.dev.rentalrecords.com',
      redirectSignIn: 'https://app.dev.rentalrecords.com/oauth/signin',
      redirectSignOut: 'https://app.dev.rentalrecords.com/oauth/signout',
      responseType: 'code',
    },
  },
});

export enum AUTH_STATE {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',

  UNKNOWN = 'UNKNOWN',
}

export enum AUTH_ERROR {
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  TooManyRequestsException = 'TooManyRequestsException',
  UserNotConfirmedException = 'UserNotConfirmedException',

  OtherException = 'OtherException',
}

export enum AUTH_STEPS {
  LOGIN = 'LOGIN',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',

  VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
}

export class AuthError extends Error {
  type = 'AuthError';

  public code: AUTH_ERROR;
  public user?: CognitoUser;

  constructor(message: string, code: AUTH_ERROR, user?: CognitoUser) {
    super(message);
    this.user = user;
    this.code = code;
  }
}

export enum SUPPORTED_THIRD_PARTY_PROVIDERS {
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
}

export type AuthState = {
  init(): Promise<void>;

  authState: AUTH_STATE;
  rememberedUsername: string | null;

  login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<{ user: CognitoUser; nextStep: AUTH_STEPS } | void>;
  completeNewPassword(user: CognitoUser, newPassword: string): Promise<void>;
  register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    turnstileToken: string
  ): Promise<ISignUpResult>;
  verifyAccount(username: string, code: string): Promise<void>;
  resendVerificationCode(username: string): Promise<void>;
  thirdPartySignon(provider: CognitoHostedUIIdentityProvider): Promise<void>;
  completeThirdPartySignon(): Promise<void>;
  logout(global?: boolean): Promise<void>;
  forgotPassword(email: string): Promise<void>;
  forgotPasswordSubmit(email: string, code: string, newPassword: string): Promise<void>;
};

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set, get) => {
        return {
          async init(): Promise<void> {
            if (get().authState !== AUTH_STATE.UNKNOWN) return;

            return Auth.currentAuthenticatedUser()
              .then<void>((u) => {
                if (!u.getSignInUserSession()?.getAccessToken().getJwtToken())
                  throw new Error('No User');
                else set({ authState: AUTH_STATE.AUTHENTICATED });
              })
              .catch<void>(() => set({ authState: AUTH_STATE.UNAUTHENTICATED }));
          },
          async getAccessToken(): Promise<string> {
            return Auth.currentAuthenticatedUser().then((u) => {
              const accessToken = u.getSignInUserSession()?.getAccessToken().getJwtToken();

              if (!accessToken) throw new Error('No Token');
              else return accessToken;
            });
          },

          authState: AUTH_STATE.UNKNOWN,
          rememberedUsername: null,

          /**
           *
           * @param { string } email
           * @param { string } password
           * @param { boolean } rememberMe
           */
          async login(email: string, password: string, rememberMe: boolean) {
            return await Auth.signIn({ username: email, password })
              .then<{ user: CognitoUser; nextStep: AUTH_STEPS } | void>((user: CognitoUser) => {
                if (user.challengeName) {
                  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    return { user, nextStep: AUTH_STEPS.FORCE_CHANGE_PASSWORD };
                  }
                }

                const accessToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();

                if (accessToken)
                  set({
                    authState: AUTH_STATE.AUTHENTICATED,
                    rememberedUsername: rememberMe ? email : null,
                  });
                else get().logout();
              })
              .catch((error) => {
                const {
                  code = AUTH_ERROR.OtherException,
                  message = 'There was an unknown error logging in',
                }: { code: AUTH_ERROR | undefined; message: string | undefined } = error;

                throw new AuthError(message, code);
              });
          },

          /**
           *
           * @param { string } oldPassword
           * @param { string } newPassword
           */
          async completeNewPassword(user: CognitoUser, newPassword: string) {
            Auth.completeNewPassword(user, newPassword);
          },

          /**
           *
           * @param { boolean } global
           */
          async logout(global: boolean = false) {
            await Auth.signOut({ global }).finally(() =>
              set({ authState: AUTH_STATE.UNAUTHENTICATED })
            );
          },

          /**
           *
           * @param email
           * @param password
           * @param firstName
           * @param lastName
           * @param turnstileToken
           * @returns
           */
          async register(
            email: string,
            password: string,
            firstName: string,
            lastName: string,
            turnstileToken: string
          ): Promise<ISignUpResult> {
            return Auth.signUp({
              username: email,
              password,
              attributes: {
                given_name: firstName,
                family_name: lastName,
              },
              autoSignIn: {
                enabled: false,
              },
              validationData: { turnstileToken },
            });
          },

          /**
           *
           * @param username
           * @param code
           */
          async verifyAccount(username: string, code: string): Promise<void> {
            Auth.confirmSignUp(username, code);
          },

          /**
           * @param username
           */
          async resendVerificationCode(username: string) {
            Auth.resendSignUp(username);
          },

          /**
           *
           * @param provider
           */
          async thirdPartySignon(provider: CognitoHostedUIIdentityProvider) {
            Auth.federatedSignIn({ provider });
          },

          /**
           *
           */
          async completeThirdPartySignon(): Promise<void> {
            try {
              const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();

              if (!currentUser) throw new Error('No User');

              const accessToken = currentUser
                .getSignInUserSession()
                ?.getAccessToken()
                .getJwtToken();

              if (accessToken)
                set({
                  authState: AUTH_STATE.AUTHENTICATED,
                });
              else throw new Error('No Access Token');
            } catch (e) {
              throw e;
            }
          },

          /**
           *
           * @param email
           */
          async forgotPassword(email: string): Promise<void> {
            await Auth.forgotPassword(email);
          },

          /**
           *
           * @param email
           * @param code
           * @param newPassword
           */
          async forgotPasswordSubmit(
            email: string,
            code: string,
            newPassword: string
          ): Promise<void> {
            await Auth.forgotPasswordSubmit(email, code, newPassword);
          },
        };
      },
      {
        name: 'auth',
        partialize: (state) => ({
          rememberedUsername: state.rememberedUsername,
        }),
      }
    )
  )
);
