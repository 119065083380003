import { useEffect } from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import Layout from '../../components/LandingLayout';
import * as Sentry from '@sentry/react';

const ErrorPage = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  Sentry.captureException(error);

  useEffect(() => {
    navigate('/');
  });

  return (
    <>
      <Layout />
    </>
  );
};

export default ErrorPage;
