import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ToggleButtonGroup, ToggleButtonGroupProps, useFormControl } from '@mui/material';
import { ReactNode } from 'react';

const FormToggleGroup = <R extends FieldValues>({
  name,
  control,
  disabled,
  children = null,
  ...toggleButtonGroupProps
}: {
  name: Path<R>;
  control: Control<R>;
  disabled?: boolean;
  children?: ReactNode;
} & ToggleButtonGroupProps) => {
  const formControl = useFormControl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, formState: { defaultValues } }) => (
        <ToggleButtonGroup
          size="small"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          sx={{ width: '100%', minWidth: { sm: '200px' } }}
          defaultValue={defaultValues?.[name]}
          disabled={disabled || formControl?.disabled}
          {...toggleButtonGroupProps}
        >
          {children}
        </ToggleButtonGroup>
      )}
    />
  );
};

export default FormToggleGroup;
