import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form';
import LandlordAutocomplete from '../form/landlordAutocomplete';

const FormLandlordAutocomplete = <R extends FieldValues>({
  name,
  control,
  label,
  disabled,
  setValue,
  country,
  state,
}: {
  name: Path<R>;
  control: Control<R>;
  label: string;
  disabled?: boolean;
  setValue: UseFormSetValue<R>;
  country?: string;
  state?: string;
}) => {
  const onChange = (newValue: string | null) => {
    if (newValue === null) return;
    else setValue(name, newValue as PathValue<R, Path<R>>);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur }, fieldState: { error } }) => (
        <LandlordAutocomplete
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          error={error}
          country={country}
          state={state}
        />
      )}
    />
  );
};

export default FormLandlordAutocomplete;
