/**
 * From environment
 */
export const USER_POOL_ID: string = process.env.USER_POOL_ID || '';
export const USER_POOL_CLIENT_ID: string = process.env.USER_POOL_CLIENT_ID || '';

export const ENV = (process.env.ENV || 'dev') as 'prod' | 'dev' | 'local';
export const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || '';
export const SENTRY_DSN: string = process.env.SENTRY_DSN || '';
export const SENTRY_ENV: string = process.env.SENTRY_ENV || '';
export const SENTRY_ENABLED: boolean = process.env.SENTRY_ENABLED === 'true';
export const TURNSTILE_SITE_KEY: string = process.env.TURNSTILE_SITE_KEY || '';
export const ANALYTICS_ID: string = process.env.REACT_APP_ANALYTICS_ID || '';
