import { Modal } from '@mui/material';
import { FunctionComponent } from 'react';
import { useModalStateStore } from '../../state/modals';
import AddPropertyModalBody from './AddPropertyModalBody';

const AddPropertyModal: FunctionComponent<Record<string, never>> = ({}) => {
  const { addPropertyOpen, closeAddPropertyModal } = useModalStateStore(
    ({ addPropertyOpen, closeAddPropertyModal }) => ({
      addPropertyOpen,
      closeAddPropertyModal,
    })
  );

  return (
    <>
      <Modal
        open={addPropertyOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') closeAddPropertyModal();
        }}
      >
        <AddPropertyModalBody />
      </Modal>
    </>
  );
};

export default AddPropertyModal;
