import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { City, Country, State, useLocationData } from '../../hooks/useLocationData';
import { useTranslation } from 'react-i18next';
import { Noop } from 'react-hook-form';

/**
 * @function CountrySelect
 *
 */
export const CountrySelect: FunctionComponent<{
  value?: string;
  defaultValue?: string;
  required?: boolean;
  onBlur?: Noop;
  onChange: (countryCode: string) => void;
  error?: unknown;
  disabled?: boolean;
}> = ({ defaultValue, value, required, onChange, onBlur, error, disabled }) => {
  const { t } = useTranslation();
  const { isLoaded, getCountries, getCountryByCode } = useLocationData();
  const [countries, setCountries] = useState<Country[] | undefined>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (defaultValue) onChange(defaultValue);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setCountries(getCountries());
      setIsDisabled(false);
    }
  }, [isLoaded]);

  const onSelect = (
    e: SyntheticEvent<Element, Event>,
    newValue:
      | string
      | {
          label?: string;
          value?: string;
        }
      | null,
    reason: 'input' | 'reset' | 'clear' | 'selectOption' | 'createOption' | 'removeOption' | 'blur'
  ) => {
    if (reason !== 'selectOption' || !newValue) return;
    if (typeof newValue === 'string') {
      onChange(newValue);
      return;
    }
    if (!newValue.label || !newValue.value) return;

    onChange(newValue.value);
  };

  const currentCountry = getCountryByCode(value || '');

  return (
    <Autocomplete
      key={`CountrySelect-${defaultValue}`}
      autoHighlight={false}
      clearOnBlur
      blurOnSelect
      disableClearable
      size="small"
      placeholder={t('components.locationDropdowns.country.label')}
      disabled={isDisabled || disabled}
      value={
        currentCountry
          ? { label: `${currentCountry.flag} ${currentCountry.name}`, value }
          : { label: '', value: '' }
      }
      onChange={onSelect}
      onBlur={onBlur}
      sx={{ width: '100%', minWidth: { sm: '200px' } }}
      isOptionEqualToValue={({ value: valueA }, { value: valueB }) => valueA === valueB}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          required={required}
          error={!!error}
          label={t('components.locationDropdowns.country.label')}
          placeholder={t('components.locationDropdowns.country.label')}
          {...params}
        />
      )}
      options={
        countries?.map(({ name, countryCode, flag }) => ({
          label: `${flag} ${name}`,
          value: countryCode,
        })) || []
      }
    />
  );
};

/**
 * @function StateSelect
 *
 */
export const StateSelect: FunctionComponent<{
  countryCode?: string;
  value?: string;
  required?: boolean;
  onBlur?: Noop;
  onChange: (countryCode: string) => void;
  error?: unknown;
  disabled?: boolean;
  disableClearable?: boolean;
}> = ({
  error,
  required,
  value,
  countryCode,
  onChange,
  onBlur,
  disabled,
  disableClearable = true,
}) => {
  const { t } = useTranslation();
  const { isLoaded, getStatesByCountry, getStateByCodeAndCountry } = useLocationData();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [states, setStates] = useState<State[] | undefined>();

  useEffect(() => {
    if (countryCode) {
      setStates(getStatesByCountry(countryCode));
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [countryCode, isLoaded]);

  const onSelect = (
    _: SyntheticEvent<Element, Event>,
    newValue:
      | string
      | {
          label: string;
          value?: string;
        }
      | null,
    reason: 'input' | 'reset' | 'clear' | 'selectOption' | 'createOption' | 'removeOption' | 'blur'
  ) => {
    if (!disableClearable && reason === 'clear') return onChange('');

    if (reason !== 'selectOption' || !newValue) return;
    if (typeof newValue === 'string') return onChange(newValue);
    if (!newValue.label || !newValue.value) return;

    onChange(newValue.value);
  };

  const currentState = getStateByCodeAndCountry(countryCode || '', value || '');

  return (
    <Autocomplete
      key={`StateSelect`}
      autoHighlight={false}
      clearOnBlur
      blurOnSelect
      disableClearable={disableClearable}
      size="small"
      placeholder={t('components.locationDropdowns.state.label')}
      value={
        currentState
          ? { label: currentState.name, value: currentState.stateCode }
          : { label: '', value: '' }
      }
      disabled={isDisabled || disabled}
      isOptionEqualToValue={({ value: valueA }, { value: valueB }) => valueA === valueB}
      onChange={onSelect}
      onBlur={onBlur}
      sx={{ width: '100%', minWidth: { sm: '200px' } }}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          required={required}
          error={!!error}
          label={t('components.locationDropdowns.state.label')}
          placeholder={t('components.locationDropdowns.state.label')}
          {...params}
        />
      )}
      options={states?.map(({ name, stateCode }) => ({ label: name, value: stateCode })) || []}
    />
  );
};

/**
 * @function CitySelect
 *
 */
export const CitySelect: FunctionComponent<{
  countryCode?: string;
  stateCode?: string;
  value?: string;
  onChange: (city: string) => void;
  onSelect?: (country: string) => void;
  required?: boolean;
  onBlur?: () => void;
  error?: unknown;
  disabled?: boolean;
}> = ({
  required,
  countryCode,
  value,
  stateCode,
  onChange: onChangeProp,
  onSelect: onSelectProp,
  onBlur,
  error,
  disabled,
}) => {
  const { t } = useTranslation();
  const { isLoaded, getCitiesByCountryState } = useLocationData();
  const [cities, setCities] = useState<City[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (countryCode && stateCode) {
      setCities(getCitiesByCountryState(countryCode, stateCode) || []);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isLoaded, countryCode, stateCode]);

  const onSelect = (
    _: SyntheticEvent<Element, Event>,
    newValue:
      | string
      | {
          label: string;
          value: string;
        }
      | null,
    reason: 'input' | 'reset' | 'clear' | 'selectOption' | 'createOption' | 'removeOption' | 'blur'
  ) => {
    if (!newValue || reason !== 'selectOption') return;

    if (onSelectProp) {
      if (typeof newValue === 'string') onSelectProp(newValue);
      else onSelectProp(newValue.value);
    } else {
      if (typeof newValue === 'string') onChangeProp(newValue);
      else onChangeProp(newValue.value);
    }
  };

  const onChange = (
    _: SyntheticEvent<Element, Event>,
    newValue:
      | string
      | {
          label: string;
          value: string;
        }
      | null,
    reason: 'input' | 'reset' | 'clear' | 'selectOption' | 'createOption' | 'removeOption' | 'blur'
  ) => {
    if (newValue === null || reason !== 'input') return;

    if (typeof newValue === 'string') onChangeProp(newValue);
    else onChangeProp(newValue.value);
  };

  return (
    <>
      <Autocomplete
        key={`CitySelect`}
        freeSolo
        disableClearable
        blurOnSelect
        size="small"
        placeholder={t('components.locationDropdowns.city.label')}
        value={value ? { label: value, value: value } : { label: '', value: '' }}
        onChange={onSelect}
        onInputChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled || disabled}
        isOptionEqualToValue={({ value: valueA }, { value: valueB }) => valueA === valueB}
        sx={{ width: '100%', minWidth: { sm: '200px' } }}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            required={required}
            error={!!error}
            onBlur={onBlur}
            label={t('components.locationDropdowns.city.label')}
            placeholder={t('components.locationDropdowns.city.label')}
            {...params}
          />
        )}
        options={cities.map(({ name }) => ({ label: name, value: name }))}
      />
    </>
  );
};
