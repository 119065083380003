import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../state/auth';

const Logout = () => {
  const n = useNavigate();
  const { logout } = useAuthStore(({ logout }) => ({
    logout,
  }));

  useEffect(() => {
    logout().then(() => n('/login'));
  }, []);

  return null;
};

export default Logout;
