import { FunctionComponent, Suspense, useEffect } from 'react';
import { Box, Container, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import AppDrawer, { DRAWER_WIDTH } from './AppDrawer';
import { AUTH_STATE, useAuthStore } from '../state/auth';
import AddPropertyModal from './modals/AddPropertyModal';
import { useAppStateStore } from '../state/app';
import { useModalStateStore } from '../state/modals';

const AppLayoutContainer: FunctionComponent<{ children?: never }> = () => {
  const n = useNavigate();
  const { openAddPropertyModal } = useModalStateStore(({ openAddPropertyModal }) => ({
    openAddPropertyModal,
  }));
  const { init, authState } = useAuthStore(({ init, authState }) => ({ init, authState }));
  const { initialize, properties } = useAppStateStore(({ initialize, properties }) => ({
    initialize,
    properties,
  }));

  useEffect(() => {
    init().then(initialize);
  }, []);

  useEffect(() => {
    if (properties !== null) {
      if (properties.length === 0) openAddPropertyModal();
    }
  }, [properties]);

  useEffect(() => {
    if (authState === AUTH_STATE.UNAUTHENTICATED) {
      n('/login');
    }
  }, [authState]);

  if (authState !== AUTH_STATE.AUTHENTICATED)
    return (
      <Box width="100vw" height="100vh" display="flex">
        <LoadingSpinner />
      </Box>
    );

  return (
    <>
      <Box display={'flex'} sx={{ backgroundColor: '#FFF' }}>
        <AppDrawer />
        <Box
          component={'main'}
          sx={{
            flexGrow: 1,
            width: { xs: '100vw', sm: `calc(100% - ${DRAWER_WIDTH}px)` },
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Toolbar />
          <Container disableGutters sx={{ flexGrow: 1 }}>
            <Suspense fallback={<LoadingSpinner />}>
              <Outlet />
            </Suspense>
          </Container>
        </Box>
      </Box>
      <AddPropertyModal />
    </>
  );
};

export default AppLayoutContainer;
