import { useFormControl } from '@mui/material';
import { FieldValues, Control, Controller, Path } from 'react-hook-form';
import ImageUploadPreviewer from '../form/ImageUploadPreviewer';

const FormImageUploadPreviewer = <R extends FieldValues>({
  name,
  control,
  disabled,
}: {
  name: Path<R>;
  control: Control<R>;
  disabled?: boolean;
}) => {
  const formControl = useFormControl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <ImageUploadPreviewer onChange={onChange} disabled={disabled || formControl?.disabled} />
      )}
    />
  );
};

export default FormImageUploadPreviewer;
