import './global.css';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { ANALYTICS_ID, ENV, SENTRY_DSN, SENTRY_ENABLED, SENTRY_ENV } from './constants/common';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './utils/reportWebVitals';
import LandingLayout from './components/LandingLayout';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import Index from '.';
import LandingErrorPage from './pages/landing/ErrorPage';
import ContactUsModal from './components/modals/ContactUsModal';
import SubmitReviewModal from './components/modals/SubmitReviewModal';
import { lazy, useEffect } from 'react';
import { useModalStateStore } from './state/modals';
import ReactGA from 'react-ga4';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { theme } from './constants/themes';
import { ToastContainer } from 'react-toastify';
import AppLayout from './components/AppLayout';
import Dashboard from './pages/app/Dashboard';
import LearnMore from './pages/landing/LearnMore';
import FederatedSignin from './pages/app/auth/FederatedSignin';
import Logout from './pages/app/auth/Logout';
import Login from './pages/app/auth/Login';
import SignUp from './pages/app/auth/SignUp';
import ForgotPassword from './pages/app/auth/ForgotPassword';

try {
  if (ENV !== 'local')
    ReactGA.initialize(ANALYTICS_ID, {
      gaOptions: {
        forceSSL: true,
      },
    });
} catch (e) {}

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: SENTRY_ENABLED,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: SENTRY_ENV,
});

const Privacy = lazy(() => import('./pages/landing/Privacy'));

const Contact = () => {
  const navigate = useNavigate();
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));

  useEffect(() => {
    openContactUsModal();
    navigate('/');
  }, []);

  return <Index />;
};

const appRouter = createBrowserRouter([
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/signup',
    Component: SignUp,
  },
  {
    path: '/logout',
    Component: Logout,
  },
  {
    path: '/forgotpassword',
    Component: ForgotPassword,
  },
  {
    path: '/oauth/*',
    Component: FederatedSignin,
  },
  {
    path: '/',
    Component: AppLayout,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
    ],
    errorElement: <LandingErrorPage />,
  },
]);

const landingRouter = createBrowserRouter([
  {
    element: <LandingLayout />,
    children: [
      { path: '/', element: <Index /> },

      { path: '/contact', element: <Contact /> },
      {
        path: '/learn-more',
        element: <LearnMore />,
      },
      {
        path: '/privacy',
        element: <Privacy />,
      },
    ],
    errorElement: <LandingErrorPage />,
  },
]);

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.querySelector("meta[name='language']")?.setAttribute('content', i18n.language);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider
        router={window.location.hostname.startsWith('app') ? appRouter : landingRouter}
      />
      <SubmitReviewModal />
      <ContactUsModal />
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Could not get 'root' element");
else createRoot(rootElement).render(<App />);

reportWebVitals();

if (ENV === 'dev' || ENV === 'prod') serviceWorker.register();
