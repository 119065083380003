import { FunctionComponent, Suspense } from 'react';
import { Container } from '@mui/material';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const LandingLayout: FunctionComponent<{ children?: never }> = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container disableGutters sx={{ flexGrow: 1 }}>
          <Suspense fallback={<LoadingSpinner />}>
            <Outlet />
          </Suspense>
        </Container>
      </Container>
    </>
  );
};

export default LandingLayout;
