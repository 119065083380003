import { FunctionComponent, memo, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Avatar,
  Container,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material';
import * as yup from 'yup';
import * as Sentry from '@sentry/react';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AUTH_STATE, useAuthStore } from '../../../state/auth';
import FormTextField from '../../../components/hookedForm/FormTextField';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTurnstile from '../../../components/hookedForm/FormTurnstile';
import { toast } from 'react-toastify';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import ReactCodeInput from 'react-code-input';
import { AuthWrapper } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

const ValidationIcon: FunctionComponent<{ isValid: boolean }> = memo(({ isValid }) => (
  <FontAwesomeIcon
    style={{ marginRight: '4px' }}
    color={isValid ? '#038127' : '#FF0000'}
    icon={faXmark}
  />
));

export const signUpFormSchema = yup.object().shape({
  firstName: yup.string().trim().required('Required'),
  lastName: yup.string().trim().required('Required'),
  email: yup.string().required('Required').email('Must be a valid email'),
  password: yup
    .string()
    .required(' ')
    .test('passwordValidation', ' ', function (password) {
      return (
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        password.length >= 8 &&
        /[0-9]/.test(password) &&
        /[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/.test(password)
      );
    }),

  turnstileResponse: yup.string().required('Required'),
});

const SignUp: FunctionComponent = () => {
  const n = useNavigate();

  const { authState, login, register, verifyAccount, thirdPartySignon } = useAuthStore(
    ({ authState, login, register, verifyAccount, thirdPartySignon }) => ({
      authState,
      login,
      register,
      verifyAccount,
      thirdPartySignon,
    })
  );

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { isSubmitting, isValid },
  } = useForm<yup.InferType<typeof signUpFormSchema>>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',

      turnstileResponse: '',
    },
    resolver: yupResolver(signUpFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTip, setShowPasswordTip] = useState(false);

  const [confirmAccount, setConfirmAccount] = useState<ISignUpResult | null>(null);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verificationCodeSubmitting, setVerificationCodeSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (authState === AUTH_STATE.AUTHENTICATED) {
      n('/');
    }
  }, [authState]);

  function handleThirdPartySignUp(provider: CognitoHostedUIIdentityProvider) {
    return function () {
      thirdPartySignon(provider);
    };
  }

  const handleRegister = async ({
    firstName,
    lastName,
    email,
    password,
    turnstileResponse,
  }: yup.InferType<typeof signUpFormSchema>): Promise<void> => {
    toast.info('Registering', {
      autoClose: false,
      toastId: 'newUserRegistering',
    });

    register(email, password, firstName, lastName, turnstileResponse)
      .then((signupResult) => {
        toast.update('newUserRegistering', {
          type: 'success',
          render: 'Success',
          autoClose: 4000,
        });
        if (!signupResult.userConfirmed && signupResult) setConfirmAccount(signupResult);
      })
      .catch((err) => {
        toast.update('newUserRegistering', {
          type: 'error',
          render: err.message || 'Unable to register',
          autoClose: 4000,
        });
      });
  };

  function handleRegisterError(err: FieldErrors<yup.InferType<typeof signUpFormSchema>>) {
    toast.error('There are still required fields missing', {
      autoClose: 2000,
      toastId: 'newReviewMissingFieldsError',
    });

    Sentry.captureEvent({
      message: 'Validation errors while submitting review',
      type: 'transaction',
      tags: Object.entries(err).reduce((acc, [key, { message }]) => {
        return { ...acc, [key]: message || '' };
      }, {}),
    });
  }

  async function handleSubmitVerificationCode(code: string) {
    if (confirmAccount?.userSub)
      return verifyAccount(confirmAccount?.userSub, code).then(() =>
        login(getValues().email, getValues().password, false)
      );
    else
      throw new Error(
        'There was an error verifying your account. Please try logging in and requesting another code.'
      );
  }

  function handleOnChangeVerificationCode(code: string) {
    setVerificationCode(code);
    if (code.length === 6) {
      setVerificationCodeSubmitting(true);
      handleSubmitVerificationCode(code).catch((e) => {
        toast.error(
          e.message ||
            'There was an error verifying your account. Please try logging in and requesting another code.',
          { autoClose: 4000 }
        );
        setVerificationCode('');
        setVerificationCodeSubmitting(false);
      });
    }
  }

  if (confirmAccount)
    return (
      <Box padding={{ xs: '1rem', sm: '3rem' }}>
        <Box display={'flex'} flexDirection={'column'} gap={'40px'}>
          <Typography variant="h1" flexGrow={1}>
            Verify Account
          </Typography>

          <ReactCodeInput
            disabled={verificationCodeSubmitting}
            style={{ margin: 'auto' }}
            inputMode="numeric"
            name="verificationCode"
            type="tel"
            fields={6}
            value={verificationCode}
            onChange={handleOnChangeVerificationCode}
          />
        </Box>
      </Box>
    );

  watch('password');

  return (
    <Box padding={{ xs: '1rem', sm: '3rem' }}>
      <Box marginBottom={'1.5rem'} display={'flex'} gap={'5px'}>
        <Typography variant="h1" flexGrow={1}>
          Sign Up
        </Typography>
        <Tooltip title="Sign-in with Facebook">
          <Avatar
            onClick={handleThirdPartySignUp(CognitoHostedUIIdentityProvider.Facebook)}
            sx={{
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.1)',
              fill: 'rgba(0, 0, 0, 0.4)',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
              cursor: 'pointer',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
              <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
            </svg>
          </Avatar>
        </Tooltip>
        <Tooltip title="Sign-in with Google">
          <Avatar
            onClick={handleThirdPartySignUp(CognitoHostedUIIdentityProvider.Google)}
            sx={{
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.1)',
              fill: 'rgba(0, 0, 0, 0.4)',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
              cursor: 'pointer',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512">
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
            </svg>
          </Avatar>
        </Tooltip>
      </Box>

      <Container disableGutters sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl
          disabled={isSubmitting}
          sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <FormTextField
            control={control}
            label={'First Name'}
            name="firstName"
            autoComplete="given-name"
          />
          <FormTextField
            control={control}
            label={'Last Name'}
            name="lastName"
            autoComplete="family-name"
          />
          <FormTextField control={control} label={'Email'} name="email" autoComplete="username" />

          <FormTextField
            control={control}
            label={'Password'}
            name="password"
            id="new-password"
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            onFocusCapture={() => setShowPasswordTip(true)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => e.preventDefault()}
                    onMouseDown={() => setShowPassword((showingStatus) => !showingStatus)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Collapse
            sx={{ px: '10px' }}
            in={
              showPasswordTip &&
              !(
                /[A-Z]/.test(getValues('password')) &&
                /[a-z]/.test(getValues('password')) &&
                getValues('password').length >= 8 &&
                /[0-9]/.test(getValues('password')) &&
                /[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/.test(getValues('password'))
              )
            }
          >
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <>
                      <ValidationIcon isValid={getValues('password').length >= 8} />
                      At least 8 characters long
                    </>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <>
                      <ValidationIcon isValid={/[a-z]/.test(getValues('password'))} />
                      At least 1 lower case character
                    </>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <>
                      <ValidationIcon isValid={/[A-Z]/.test(getValues('password'))} />
                      At least 1 upper case character
                    </>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <>
                      <ValidationIcon isValid={/[0-9]/.test(getValues('password'))} />
                      At least 1 number
                    </>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <>
                      <ValidationIcon
                        isValid={/[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/.test(
                          getValues('password')
                        )}
                      />
                      At least 1 symbol
                    </>
                  }
                />
              </ListItem>
            </List>
          </Collapse>

          <FormTurnstile
            control={control}
            name="turnstileResponse"
            options={{ action: 'signup' }}
          />

          <Button
            disabled={isSubmitting || !isValid}
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={handleSubmit(handleRegister, handleRegisterError)}
          >
            Sign Up
          </Button>
        </FormControl>

        <Box display={'flex'} textAlign={'center'} mx={'auto'} mt={'20px'} flexGrow={1}>
          <Typography>Already have an account?</Typography>&nbsp;
          <Link to={'/login'} style={{ fontWeight: 400 }}>
            <Typography sx={{ fontWeight: 400 }}>Sign In</Typography>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default () => (
  <AuthWrapper>
    <SignUp />
  </AuthWrapper>
);
