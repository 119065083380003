import { FunctionComponent, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_ERROR, AUTH_STATE, AUTH_STEPS, AuthError, useAuthStore } from '../../../state/auth';
import ReactCodeInput from 'react-code-input';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { AuthWrapper } from '.';

const Login: FunctionComponent = () => {
  const n = useNavigate();
  const {
    authState,
    rememberedUsername,
    login,
    completeNewPassword,
    verifyAccount,
    resendVerificationCode,
    thirdPartySignon,
  } = useAuthStore(
    ({
      authState,
      rememberedUsername,
      login,
      completeNewPassword,
      verifyAccount,
      resendVerificationCode,
      thirdPartySignon,
    }) => ({
      authState,
      rememberedUsername,
      login,
      completeNewPassword,
      verifyAccount,
      resendVerificationCode,
      thirdPartySignon,
    })
  );

  const [unauthenticatedUser, setUnauthenticatedUser] = useState<CognitoUser | null>(null);

  const [email, setEmail] = useState<string>(rememberedUsername || '');
  const [password, setPassword] = useState<string>('');
  const [shouldRemember, setShouldRemember] = useState<boolean>(rememberedUsername !== null);

  const [needsNewPassword, setNeedsNewPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  const [needsValidation, setNeedsValidation] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (authState === AUTH_STATE.AUTHENTICATED) {
      n('/');
    }
  }, [authState]);

  function handleThirdPartySignIn(provider: CognitoHostedUIIdentityProvider) {
    return function () {
      thirdPartySignon(provider);
    };
  }

  function handleCognitoSignIn() {
    setIsSubmitting(true);

    login(email, password, shouldRemember)
      .then((nextStepsResponse) => {
        if (nextStepsResponse) {
          setUnauthenticatedUser(nextStepsResponse.user);
          if (nextStepsResponse.nextStep === AUTH_STEPS.FORCE_CHANGE_PASSWORD) {
            setNeedsNewPassword(true);
          }
          setIsSubmitting(false);
        }
      })
      .catch((loginError: AuthError) => {
        switch (loginError.code) {
          case AUTH_ERROR.UserNotConfirmedException:
            setNeedsValidation(true);
            break;

          case AUTH_ERROR.PasswordResetRequiredException:
            setNeedsNewPassword(true);
            setErrorMessage('');
            break;
          default:
            setErrorMessage(loginError.message);

            setIsSubmitting(false);
        }
      });
  }

  function handleCompleteNewPassword() {
    setIsSubmitting(true);
    if (unauthenticatedUser) {
      completeNewPassword(unauthenticatedUser, newPassword).then(() => {
        login(email, newPassword, shouldRemember);
        setIsSubmitting(false);
      });
    } else {
      setIsSubmitting(false);
    }
  }

  function handleSubmitVerificationCode() {
    verifyAccount(email, verificationCode)
      .then(() => {
        login(email, password, shouldRemember);
        setIsSubmitting(false);
      })
      .catch(() => setIsSubmitting(false));
  }

  function handleResendVerificationCode() {
    toast.info(`Sending code to ${email}`, {
      autoClose: false,
      toastId: 'resendVerificationCode',
    });
    resendVerificationCode(email)
      .then(() => {
        toast.update('resendVerificationCode', {
          render: `Sent a new code to ${email}`,
          delay: 4000,
          type: 'success',
        });
      })
      .catch((err) => {
        toast.update('resendVerificationCode', {
          render: `Unable to resend verification code. Please contact support`,
          delay: 4000,
          type: 'error',
        });

        Sentry.captureException(err, {
          contexts: {
            email: {
              to: email,
            },
          },
        });
      });
  }

  if (needsValidation)
    return (
      <Box padding={{ xs: '1rem', sm: '3rem' }}>
        <Box marginBottom={'1.5rem'} display={'flex'} flexDirection={'column'} gap={'5px'}>
          <Typography variant="h1" flexGrow={1}>
            Verify Account
          </Typography>
          <Typography variant="subtitle1">Enter the code sent to your email</Typography>

          <ReactCodeInput
            style={{ margin: 'auto' }}
            inputMode="numeric"
            name="verificationCode"
            type="tel"
            fields={6}
            value={verificationCode}
            onChange={(code) => setVerificationCode(code)}
          />

          <Button disabled={isSubmitting} onClick={handleSubmitVerificationCode}>
            Submit
          </Button>

          <Box display={'flex'} textAlign={'center'} mx={'auto'}>
            <a href="#" onClick={handleResendVerificationCode}>
              <Typography>Resend code</Typography>
            </a>
          </Box>
        </Box>
      </Box>
    );

  if (needsNewPassword)
    return (
      <Box padding={{ xs: '1rem', sm: '3rem' }}>
        <Box marginBottom={'1.5rem'} display={'flex'} gap={'5px'}>
          <Typography variant="h1" flexGrow={1}>
            New Password
          </Typography>
        </Box>

        <Container
          disableGutters
          component={'form'}
          sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          <TextField
            value={newPassword}
            onChange={({ target: { value } }) => setNewPassword(value)}
            fullWidth
            label="New Password"
            size="small"
            type="password"
            autoComplete="new-password"
          />

          <TextField
            value={confirmNewPassword}
            onChange={({ target: { value } }) => setConfirmNewPassword(value)}
            fullWidth
            label="Confirm Password"
            size="small"
            type="password"
            autoComplete="new-password"
          />

          {errorMessage && (
            <Typography textAlign={'center'} color={'#ee0000'} variant="subtitle1">
              {errorMessage}
            </Typography>
          )}

          <Button
            onClick={handleCompleteNewPassword}
            variant="contained"
            sx={{ textTransform: 'none' }}
            disabled={isSubmitting}
          >
            Submit Password
          </Button>
        </Container>
      </Box>
    );

  return (
    <Box padding={{ xs: '1rem', sm: '3rem' }}>
      <Box marginBottom={'1.5rem'} display={'flex'} gap={'5px'}>
        <Typography variant="h1" flexGrow={1}>
          Sign In
        </Typography>
        <Tooltip title="Sign-in with Facebook">
          <Avatar
            onClick={handleThirdPartySignIn(CognitoHostedUIIdentityProvider.Facebook)}
            sx={{
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.1)',
              fill: 'rgba(0, 0, 0, 0.4)',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
              cursor: 'pointer',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
              <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
            </svg>
          </Avatar>
        </Tooltip>
        <Tooltip title="Sign-in with Google">
          <Avatar
            onClick={handleThirdPartySignIn(CognitoHostedUIIdentityProvider.Google)}
            sx={{
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.1)',
              fill: 'rgba(0, 0, 0, 0.4)',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
              cursor: 'pointer',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512">
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
            </svg>
          </Avatar>
        </Tooltip>
      </Box>

      <Container
        disableGutters
        component={'form'}
        sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      >
        <TextField
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          fullWidth
          label="Email"
          size="small"
          autoComplete="username"
        />

        <TextField
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          fullWidth
          label="Password"
          size="small"
          type="password"
          autoComplete="current-password"
        />

        {errorMessage && (
          <Typography textAlign={'center'} color={'#ee0000'} variant="subtitle1">
            {errorMessage}
          </Typography>
        )}

        <Button
          disabled={!email || !password || isSubmitting}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleCognitoSignIn();
          }}
          variant="contained"
          sx={{ textTransform: 'none' }}
        >
          Sign In
        </Button>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              sx={{ margin: 0 }}
              label="Remember Me"
              value={shouldRemember}
              control={
                <Checkbox
                  checked={shouldRemember}
                  onChange={({ target: { checked } }) => setShouldRemember(checked)}
                  color="primary"
                  sx={{ padding: 0, mr: '5px' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'right' }}>
            <Link to={'/forgotpassword'}>
              <Typography sx={{ fontWeight: 400 }}>Forgot Password</Typography>
            </Link>
          </Grid>
        </Grid>

        <Box display={'flex'} textAlign={'center'} mx={'auto'}>
          <Typography>Need an account?</Typography>&nbsp;
          <Link to={'/signup'} style={{ fontWeight: 400 }}>
            <Typography sx={{ fontWeight: 400 }}>Sign Up</Typography>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default () => (
  <AuthWrapper>
    <Login />
  </AuthWrapper>
);
