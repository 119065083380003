import en from './en';
import es from './es';
import fr from './fr';
import kr from './kr';

export const translationResources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  kr: {
    translation: kr,
  },
};

export const languageOptions: {
  [key in keyof typeof translationResources]: {
    live: boolean;
    nativeName: string;
  };
} = {
  en: {
    live: true,
    nativeName: 'English',
  },
  es: {
    live: false,
    nativeName: 'Español',
  },
  fr: {
    live: false,
    nativeName: 'Français',
  },
  kr: {
    live: false,
    nativeName: '한국어',
  },
};

export const availableLanguages = Object.entries(languageOptions).filter(
  ([_, { live }]) => live //|| ENV !== 'prod'
);
