import { Rating, Typography, useFormControl } from '@mui/material';
import { useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

const COLORS = ['#ff8c5a', '#ffb234', '#ffd934', '#add633', '#a0c15a'];

const FormRating = <R extends FieldValues>({
  name,
  control,
  label,
  disabled,
  colored,
}: {
  name: Path<R>;
  control: Control<R>;
  label: string;
  disabled?: boolean;
  colored?: boolean;
}) => {
  const formControl = useFormControl();
  const [hover, setHover] = useState<number>(-1);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <>
          <Typography component="legend">{label}</Typography>
          <Rating
            sx={{
              ...(colored
                ? {
                    '&:hover label': {
                      color: COLORS[Math.ceil(hover) - 1],
                    },
                    '& label': {
                      color: COLORS[Math.ceil(value) - 1],
                    },
                  }
                : {}),
            }}
            precision={0.5}
            name={label}
            title={label}
            disabled={disabled || formControl?.disabled}
            onBlur={onBlur}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            onChangeActive={(_, newHover) => setHover(newHover)}
          />
          <Typography
            color={'#d32f2f'}
            variant="subtitle1"
            display={!!error ? 'block' : 'none'}
            component="legend"
          >
            {error?.message}
          </Typography>
        </>
      )}
    />
  );
};

export default FormRating;
