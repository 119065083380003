import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box, useFormControl } from '@mui/material';

const FormTextField = <R extends FieldValues>({
  name,
  control,
  label,
  disabled,
  charCount,
  ...textFieldProps
}: {
  name: Path<R>;
  control: Control<R>;
  label: string;
  disabled?: boolean;
  charCount?: number;
} & TextFieldProps) => {
  const formControl = useFormControl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <TextField
          size="small"
          error={!!error}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          helperText={
            error?.message ? (
              <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{error.message}</span>
                {charCount && <span>{`${(value as string).length} / ${charCount}+`}</span>}
              </Box>
            ) : (
              <> </>
            )
          }
          sx={{ width: '100%', minWidth: { sm: '200px' } }}
          label={label}
          disabled={disabled || formControl?.disabled}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default FormTextField;
