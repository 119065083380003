import { useEffect, useState } from 'react';

export type City = {
  name: string;
  latitude: string;
  longitude: string;
};

export type State = {
  stateCode: string;
  name: string;
  latitude: string;
  longitude: string;
};

export type Country = {
  countryCode: string;
  name: string;
  flag: string;
  latitude: string;
  longitude: string;
};

class LocationDataLoader {
  static instance: LocationDataLoader;

  static getInstance() {
    if (!this.instance) {
      this.instance = new LocationDataLoader();
    }
    return this.instance;
  }

  private loadDataPromise: Promise<void>;

  constructor() {
    this.loadDataPromise = window.loadData();
  }

  loadData(): Promise<void> {
    return this.loadDataPromise;
  }
}

export function useLocationData() {
  const [isLoaded, setIsLoaded] = useState<boolean>(
    window.countries !== undefined && window.states !== undefined && window.cities !== undefined
  );

  useEffect(() => {
    if (isLoaded) return;

    LocationDataLoader.getInstance()
      .loadData()
      .then(() => setIsLoaded(true));
  }, []);

  const getCountries = () => {
    if (!isLoaded) return;
    else return window.getCountries();
  };
  const getCountryByCode = (isoCode: string) => {
    if (!isLoaded) return;
    else return window.getCountries().find(({ countryCode }) => countryCode === isoCode);
  };
  const getStatesByCountry = (countryCode: string) => {
    if (!isLoaded) return;
    return window.getStatesByCountry(countryCode);
  };
  const getStateByCodeAndCountry = (countryCode: string, stateCode: string) => {
    if (!isLoaded) return;
    return window.getStatesByCountry(countryCode)?.find(({ stateCode: sc }) => sc === stateCode);
  };
  const getCitiesByCountryState = (countryCode: string, stateCode: string) => {
    if (!isLoaded) return;
    return window.getCitiesByCountryState(countryCode, stateCode);
  };

  return {
    isLoaded,
    getCountries,
    getCountryByCode,
    getStatesByCountry,
    getStateByCodeAndCountry,
    getCitiesByCountryState,
  };
}
