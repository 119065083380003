import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormTrigger,
  useController,
} from 'react-hook-form';
import { CitySelect } from '../form/locationDropdowns';
import { useEffect, useState } from 'react';

const FormCityAutocomplete = <R extends FieldValues>({
  name,
  control,
  setValue,
  stateCode,
  countryCode,
  trigger,
}: {
  name: Path<R>;
  control: Control<R>;
  setValue: UseFormSetValue<R>;
  stateCode?: string;
  countryCode?: string;
  trigger: UseFormTrigger<R>;
}) => {
  const {
    fieldState: { isTouched },
  } = useController({ name, control });

  const onChange = (newValue: string) => {
    setValue(name, newValue as PathValue<R, Path<R>>);
    if (isTouched) trigger(name, { shouldFocus: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, value }, fieldState: { error } }) => (
        <CitySelect
          value={value}
          onBlur={onBlur}
          stateCode={stateCode}
          countryCode={countryCode}
          onChange={onChange}
          error={!!error}
        />
      )}
    />
  );
};

export default FormCityAutocomplete;
