import { Modal } from '@mui/material';
import { FunctionComponent } from 'react';
import SubmitReviewModalBody from './SubmitReviewModalBody';

const SubmitReviewModal: FunctionComponent<Record<string, never>> = ({}) => {
  return (
    <>
      <Modal keepMounted={false} open={false} onClose={() => {}}>
        <SubmitReviewModalBody />
      </Modal>
    </>
  );
};

export default SubmitReviewModal;
