import type { TestFunction, ValidationError } from 'yup';

export function postalCodeYupTest(
  countryKey: string,
  stateKey: string
): TestFunction<string | undefined> {
  return function (postal: string = '', { parent, createError }): boolean | ValidationError {
    const country: string | undefined = parent[countryKey];
    const state: string | undefined = parent[stateKey];

    if (!country || !state) return true;

    try {
      if (country === 'CA') {
        if (
          !/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(postal.trim())
        )
          throw new Error();

        if (state === 'AB' && /^t.*/i.test(postal)) {
        } else if (state === 'BC' && /^v.*/i.test(postal)) {
        } else if (state === 'MB' && /^r.*/i.test(postal)) {
        } else if (state === 'NB' && /^e.*/i.test(postal)) {
        } else if (state === 'NL' && /^a.*/i.test(postal)) {
        } else if (state === 'NT' && /^x.*/i.test(postal)) {
        } else if (state === 'NS' && /^b.*/i.test(postal)) {
        } else if (state === 'NU' && /^x.*/i.test(postal)) {
        } else if (state === 'ON' && /^[klmnp].*/i.test(postal)) {
        } else if (state === 'PE' && /^c.*/i.test(postal)) {
        } else if (state === 'QC' && /^[ghj].*/i.test(postal)) {
        } else if (state === 'SK' && /^s.*/i.test(postal)) {
        } else if (state === 'YT' && /^y.*/i.test(postal)) {
        } else {
          throw new Error();
        }
      } else if (country === 'US') {
        if (/^\d{5}(-\d{4})?$/.test(postal.trim())) throw new Error();
      } else {
        if (!/^(?:[A-Za-z0-9]+([- ]?[A-Za-z0-9]+)*)?$/.test(postal)) throw new Error();
      }

      return true;
    } catch {
      return createError({ message: 'Postal code is not valid' });
    }
  };
}
