import { FunctionComponent, useEffect } from 'react';
import { Container, Box, Typography, Button, Grid, Divider } from '@mui/material';
import bannerImagePng from './assets/images/banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseLaptop, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { removeUtms } from './utils/common';

const Index: FunctionComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: 'Landing Page',
      hitCallback: removeUtms,
    });
  }, []);

  return (
    <>
      {/** Top Banner Section **/}
      <Container>
        <Container
          role="banner"
          sx={{
            backgroundColor: '#f2f2f2',
            width: { xs: '100%' },
            maxWidth: { xs: '100%' },
            height: '450px',
            margin: 0,
            py: '25px',
          }}
        >
          <Box display={'flex'}>
            <Box width="100%" height="100%" display="flex">
              <Box
                px={'25px'}
                boxSizing={'border-box'}
                sx={{ my: 'auto', textAlign: 'left', width: '50%' }}
              >
                <Typography
                  sx={{
                    fontSize: '28px',
                    color: 'black',
                  }}
                >
                  Protect Yourself
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    marginTop: '20px',
                    fontSize: '28px',
                    color: 'black',
                  }}
                >
                  Keep Your Rental Records in a Safe Place
                </Typography>
                <Typography
                  sx={{
                    marginTop: '20px',
                    color: 'black',
                  }}
                >
                  It can be tough to keep track of your documents in the simplest of times. When
                  you're going through a move, this can be an impossible task. Keep track of
                  everything in Rental Records with reminders
                </Typography>
                <Container
                  sx={{
                    display: { xs: 'grid', sm: 'block' },
                    marginTop: { xs: '20px' },
                    gap: { xs: '10px 0' },
                  }}
                >
                  <Button onClick={() => {}} sx={{ marginX: '5px' }} variant="contained">
                    Protect Yourself
                  </Button>
                  <Button
                    sx={{
                      marginX: '5px',
                      backgroundColor: 'white',
                      ':hover': { backgroundColor: 'rgb(210 233 255)' },
                    }}
                    variant="outlined"
                    onClick={() => navigate('/reviews')}
                  >
                    Learn More
                  </Button>
                </Container>
              </Box>

              <Box borderRadius={'50px'} overflow={'hidden'} flexGrow={1} maxWidth={'50%'}>
                <img width={'100%'} src={bannerImagePng} />
              </Box>
            </Box>
          </Box>
        </Container>
      </Container>

      {/** Informational Boxes **/}
      <Container maxWidth={false} sx={{ backgroundColor: '#FFF' }}>
        <Container
          maxWidth="lg"
          sx={{
            position: 'relative',
            width: { xs: '100%' },
            maxWidth: { xs: '1200px' },
            minHeight: '100px',
            height: 'fit-content',
            my: 'auto',
            padding: { xs: 0, md: '50px 0' },
            overflow: 'hidden',
          }}
        >
          <Grid container spacing={10} padding={'10px'}>
            {[
              {
                heading: 'Convenient',
                subHeading:
                  'Your lease agreement and any photos/videos taken upon move in with upload timestamps. When you move out, simply log in and review what the place looked like when the lease started.',
                icon: faHouseLaptop,
              },
              {
                heading: 'Secure',
                subHeading:
                  'Anything you uploaded is fully encryped in transit and at rest, and can be deleted by you at anytime. Your data is only accessible to you and those you explicitly choose to share it with.',
                icon: faUsersViewfinder,
              },
            ].map(({ heading, subHeading, icon }, idx) => (
              <Grid key={`callstoaction-${idx}`} item xs={12} md={6}>
                <Box borderRadius={'4px'} sx={{ backgroundColor: '#fff', padding: '25px' }}>
                  <FontAwesomeIcon
                    style={{ margin: '0 0 20px 20px' }}
                    fontSize="5rem"
                    color={theme.palette.primary.main}
                    icon={icon}
                  />
                  <Typography sx={{ marginBottom: { sm: '5px', md: '15px' } }} variant="h3">
                    {heading}
                  </Typography>
                  <Divider
                    sx={{
                      border: '3px solid rgba(0, 0, 0, 0.60)',
                      width: '70px',
                      my: '10px',
                    }}
                  />
                  <Typography>{subHeading}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>

      {/** Features **/}
      <Container maxWidth={false} sx={{ backgroundColor: '#f2f2f2' }}>
        <Container
          maxWidth="lg"
          sx={{
            position: 'relative',
            width: { xs: '100%' },
            maxWidth: { xs: '1200px' },
            minHeight: '100px',
            height: 'fit-content',
            my: 'auto',
            padding: { xs: 0, md: '60px 0' },
            overflow: 'hidden',
          }}
        >
          <Box width="100%" height="100%" display="flex">
            Pricing
          </Box>
        </Container>
      </Container>
    </>
  );
};

export default Index;
