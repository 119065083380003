import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Property, User } from '../providers/rentalrecords/types';
import { getUserData } from '../providers/rentalrecords/client';

export type SearchParams = {
  landlordName: string;
  country: string;
  state: string;
  city: string;
  postal: string;
};

interface AppState {
  readonly currentUser: (Omit<User, 'properties'> & Partial<Pick<User, 'properties'>>) | null;
  readonly properties: Property[] | null;
  readonly currentProperty: Property | null;

  initialize: () => void;
  setCurrentProperty: (propertyId: string) => void;
}

export const useAppStateStore = create<AppState>()(
  devtools((set) => {
    return {
      currentUser: null,
      properties: null,
      currentProperty: null,

      async initialize() {
        const initialData = await getUserData();

        set({
          currentUser: initialData,
          properties: initialData.properties,
          currentProperty: initialData.properties[0],
        });
      },

      setCurrentProperty(propertyId: string) {
        set(({ properties }) => ({
          currentProperty: properties?.find(({ id }) => id === propertyId) || null,
        }));
      },
    };
  })
);
