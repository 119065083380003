import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ErrorBoundary } from '../ErrorBoundary';
import { Turnstile, TurnstileProps } from '@marsidev/react-turnstile';
import { TURNSTILE_SITE_KEY } from '../../constants/common';
import { useState } from 'react';

const FormRecaptcha = <R extends FieldValues>({
  name,
  control,
  options,
}: {
  name: Path<R>;
  control: Control<R>;
  options: TurnstileProps['options'];
}) => {
  const [turnstileExpired, setTurnstileExpired] = useState<boolean>(false);
  const [turnstileNotsupported, setTurnstileNotsupported] = useState<boolean>(false);
  const [interactive, setInteractive] = useState<boolean>(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <ErrorBoundary fallback={<Typography>Error Loading ReCaptcha</Typography>}>
          <Turnstile
            siteKey={TURNSTILE_SITE_KEY}
            onBeforeInteractive={() => setInteractive(true)}
            onAfterInteractive={() => setInteractive(false)}
            onSuccess={(token) => {
              setTurnstileExpired(false);
              onChange(token);
            }}
            onExpire={() => setTurnstileExpired(true)}
            onUnsupported={() => setTurnstileNotsupported(true)}
            options={{
              theme: 'light',
              responseField: false,
              size: interactive ? 'normal' : 'invisible',
              refreshExpired: 'manual',
              ...options,
            }}
          />
          <Typography
            textAlign={'center'}
            color={'#d32f2f'}
            variant="subtitle1"
            display={!!error ? 'block' : 'none'}
            component="legend"
          >
            {error?.message}
          </Typography>
          <Typography
            textAlign={'center'}
            color={'#d32f2f'}
            variant="subtitle1"
            display={turnstileExpired || turnstileNotsupported ? 'block' : 'none'}
            component="legend"
          >
            {turnstileNotsupported
              ? 'Cloudflare Turnstile Not Supported on this Browser'
              : turnstileExpired
              ? 'Validation Expired'
              : null}
          </Typography>
        </ErrorBoundary>
      )}
    />
  );
};

export default FormRecaptcha;
