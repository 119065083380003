import { FunctionComponent, ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import topImageSrc from '../../../assets/images/vancouverSkyline.webp';

export const AuthWrapper: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
  <Box height={'100vh'}>
    <Container disableGutters sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <Box
        width={'100%'}
        maxWidth={{ xs: '100%', sm: '400px' }}
        margin={{ xs: 'auto', sm: 'auto' }}
        borderRadius={{ xs: 0, sm: '8px' }}
        bgcolor={'#fff'}
        boxShadow={'0 15px 30px 0 rgba(0, 0, 0, 0.2)'}
        overflow={'hidden'}
      >
        <Box
          height={{ xs: '200px' }}
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: `url(${topImageSrc})`,
          }}
        />
        {children}
      </Box>
    </Container>
  </Box>
);
