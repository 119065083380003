const translations = {
  header: {
    menu: {
      buttons: {
        reviews: {
          label: 'Reviews',
        },
        resources: {
          label: 'Resources',
        },
        tenantResources: {
          label: 'Tenant Resources',
        },
        tenantUnions: {
          label: 'Tenant Unions',
        },
        about: {
          label: 'About',
        },
        faq: {
          label: 'FAQ',
        },
        contact: {
          label: 'Contact',
        },
        submitReview: {
          label: 'Submit a Review',
        },
      },
    },
  },
  footer: {
    privacyPolicy: 'Privacy Policy',
  },
  components: {
    locationDropdowns: {
      country: { label: 'Country' },
      state: { label: 'State' },
      city: { label: 'City' },
    },
  },
  landingPage: {
    banner: {
      headline: 'Helping each other as tenants by shining a spotlight on landlords',
      buttons: {
        viewReviews: {
          label: 'View Reviews',
        },
        submitReview: {
          label: 'Submit a Review',
        },
      },
    },
    callsToAction: {
      headline: 'Help other tenants',
      accountability: {
        heading: 'Accountability',
        body: 'Keep landlords accountable for their behaviour',
      },
      transparency: {
        heading: 'Transparency',
        body: 'Enable others to be aware of past issues with negative landlords',
      },
      raiseStandards: {
        heading: 'Raise Standards',
        body: "Landlords don't have to maintain standards if there are no consequences",
      },
    },
  },
  reviewsPage: {
    filters: {
      show: 'Show',
      hide: 'Hide',
      filters: 'Filters',
      postal: {
        label: 'Postal',
      },
      landlord: {
        label: 'Landlord Name',
      },
    },
    reviewBox: {
      labels: {
        repair: 'Repair',
        stability: 'Stability',
        privacy: 'Privacy',
        respect: 'Respect',
      },
      landlordAverage: 'landlord average',
      errorFetchingHeader: 'Error while fetching reviews',
    },
  },
};

export default translations;
