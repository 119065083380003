import {
  AppBar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import logoImage from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faGear,
  faHouse,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAppStateStore } from '../state/app';

export const DRAWER_WIDTH = 240;

const DrawerContent = () => {
  const n = useNavigate();
  const { properties } = useAppStateStore(({ properties }) => ({ properties }));

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Toolbar>
        <Box
          onClick={() => n('/')}
          sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, cursor: 'pointer' }}
        >
          <Box
            component="img"
            sx={{ width: '36px', height: '36px', overflow: 'clip' }}
            src={logoImage}
            alt="Rental Records Logo"
          />
        </Box>
        <Typography fontWeight={800} sx={{ cursor: 'pointer' }} onClick={() => n('/')} pl="5px">
          RentalRecords.com
        </Typography>
      </Toolbar>
      <Divider />

      {properties && properties.length > 1 && (
        <List disablePadding>
          <ListItem>
            <Select size="small" fullWidth defaultValue={properties[0].id}>
              {properties.map(({ id, displayName, address: { address } }) => (
                <MenuItem key={`drawer-propertyListSelector-Property-${id}`} value={id}>
                  {displayName || address}
                </MenuItem>
              ))}
            </Select>
          </ListItem>
        </List>
      )}

      {/* <List>
        {[
          { label: 'Property', icon: faHouse },
          { label: 'Share', icon: faShare },
        ].map(({ label, icon }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton
              sx={{
                '&:hover': {
                  fontWeight: 600,
                },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <FontAwesomeIcon size="xl" icon={icon} />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              sx={{
                '&:hover': {
                  fontWeight: 600,
                },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider /> */}
      <Box p={0} mt={'auto'}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                '&:hover': {
                  '& svg:first-of-type': {
                    animationName: 'rotate',
                    animationDuration: '2s',
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear',
                  },
                },
                '@keyframes rotate': {
                  from: { transform: 'rotate(0deg)' },
                  to: { transform: 'rotate(360deg)' },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: 'inherit',
                }}
              >
                <FontAwesomeIcon size="xl" icon={faGear} />
              </ListItemIcon>
              <ListItemText primary={'Settings'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => n('/logout')}>
              <ListItemIcon
                sx={{
                  color: 'inherit',
                }}
              >
                <FontAwesomeIcon size="xl" icon={faArrowRightFromBracket} />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

const AppDrawer = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <>
      <AppBar
        key={'app-toolbar'}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { sm: `${DRAWER_WIDTH}px` },
          backgroundColor: '#FFF',
          color: '#000',
          boxShadow: 'none',
          borderBottom: '0 solid rgba(0, 0, 0, 0.12)',
          borderBottomWidth: 'thin',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        key={'app-drawer-container'}
        component={'nav'}
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
        >
          <DrawerContent />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
          PaperProps={{ sx: { borderRight: 'none' } }}
          open
        >
          <DrawerContent />
        </Drawer>
      </Box>
    </>
  );
};

export default AppDrawer;
