import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Container,
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  Alert,
  Grid,
  CircularProgress,
  Divider,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { useModalStateStore } from '../../state/modals';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { t } from 'i18next';
import FormCityAutocomplete from '../hookedForm/FormCityAutocomplete';
import FormCountryAutocomplete from '../hookedForm/FormCountryAutocomplete';
import FormStateAutocomplete from '../hookedForm/FormStateAutoComplete';
import FormTextField from '../hookedForm/FormTextField';
import { postalCodeYupTest } from '../../utils/yupValidators';
import { getCountryFromTimezone } from '../../utils/userLocation';
import { addDays, addYears } from 'date-fns';
import FormDatePicker from '../hookedForm/FormDatePicker';

export const rentalInformationSchema = yup.object().shape({
  propertyCountry: yup.string().required('Required'),
  propertyState: yup.string().required('Required'),
  propertyCity: yup.string(),
  propertyPostal: yup
    .string()
    .test('ensure_valid_postal', '', postalCodeYupTest('propertyCountry', 'propertyState')),
  propertyAddress: yup.string().optional(),

  leaseEndDate: yup.date().required('Lease End Date is required'),

  propertyDisplay: yup.string().optional(),
  notifyPriorToLeaseEnd: yup.boolean(),
  notifyAfterLeaseEnd: yup.boolean(),
  autoDeleteDataAfterLeaseEnd: yup.boolean(),
});

const AddPropertyModalBody = forwardRef(() => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { closeAddPropertyModal } = useModalStateStore(({ closeAddPropertyModal }) => ({
    closeAddPropertyModal,
  }));

  const {
    setValue,
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<yup.InferType<typeof rentalInformationSchema>>({
    defaultValues: {
      propertyCountry: getCountryFromTimezone(),
      propertyState: '',
      propertyCity: '',
      propertyPostal: '',
      propertyAddress: '',

      leaseEndDate: addYears(new Date(), 1),

      propertyDisplay: 'Home',

      notifyPriorToLeaseEnd: true,
      notifyAfterLeaseEnd: true,
      autoDeleteDataAfterLeaseEnd: false,
    },
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: {
          xs: '100%',
          sm: '85%',
        },
        height: {
          xs: '100%',
          sm: 'fit-content',
        },
        maxWidth: '850px',
        width: {
          xs: '100%',
          sm: '90%',
          md: '80%',
        },
        overflowY: 'scroll',
        p: '20px 5px',
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: { xs: '0px', sm: '8px' },
        boxShadow: 24,
      }}
    >
      <Button sx={{ position: 'absolute', top: '5px', right: '0' }} onClick={closeAddPropertyModal}>
        <FontAwesomeIcon size="2x" icon={faXmark} />
      </Button>
      <Box width={'100%'}>
        <Stepper sx={{ my: { xs: '20px', sm: '10px' } }} activeStep={0}>
          <Step>
            <StepLabel>
              <Typography sx={{ display: { xs: 'none', sm: 'inherit' } }}>
                Add Rental Info
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel optional={<Typography variant="caption">Optional</Typography>}>
              <Typography sx={{ display: { xs: 'none', sm: 'inherit' } }}>Uploads</Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography sx={{ display: { xs: 'none', sm: 'inherit' } }}>Confirmation</Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Typography variant="h2">Step 1</Typography>

      <FormControl disabled={isSubmitting}>
        <Container component="section">
          <Alert severity="info" sx={{ my: '5px' }}>
            Thank you for being one of our first users! Usually we charge a $5 fee plus a refundable
            $10 data storage deposit (returned when data deleted from storage); however, as a thank
            you for giving us a try so early this is waived for you.
          </Alert>
          <Typography variant="h3">Rental Information</Typography>
          <Typography p={'5px'}>
            We ask for this so we know where our service is being used and how long to expect data
            to be stored
          </Typography>
          <Grid container mt={0} spacing={'10px'}>
            <Grid item xs={12} sm={6} md={4}>
              <FormCountryAutocomplete
                name="propertyCountry"
                control={control}
                setValue={setValue}
                fieldsToClear={['propertyState', 'propertyCity', 'propertyPostal']}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormStateAutocomplete
                name="propertyState"
                control={control}
                setValue={setValue}
                countryCode={watch('propertyCountry')}
                fieldsToClear={['propertyCity', 'propertyPostal']}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormCityAutocomplete
                key={'city-autocomplete'}
                name="propertyCity"
                control={control}
                setValue={setValue}
                countryCode={watch('propertyCountry')}
                stateCode={watch('propertyState')}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                name="propertyPostal"
                control={control}
                label={t('reviewsPage.filters.postal.label')}
                disabled={!watch('propertyCountry') || !watch('propertyState')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormTextField
                name="propertyAddress"
                control={control}
                label={t('Address (Optional)')}
                disabled={!watch('propertyCountry') || !watch('propertyState')}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDatePicker
                name="leaseEndDate"
                control={control}
                label={'Lease End Date'}
                minDate={addDays(new Date(), 1)}
                slotProps={{
                  textField: {
                    helperText: 'Can be extended later if needed',
                    size: 'small',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Container>

        <Divider sx={{ my: '15px' }} />

        <Container component="section">
          <Typography p={'5px'}>
            How the property will be displayed and notification settings
          </Typography>
          <Grid mt={0} container spacing={'10px'}>
            <Grid item xs={12} sm={6} md={6}>
              <FormTextField
                name="propertyDisplay"
                control={control}
                label={t('Display Name (Optional)')}
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid mt={0} container spacing={'10px'}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch />}
                label={
                  <Typography>
                    Notify me a week prior to lease end to remind me where my documents are
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch />}
                label={
                  <Typography>
                    Notify me a week after end of lease to remind me to cancel, delete my data, and
                    get a refund
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch />}
                label={
                  <Typography>
                    Automatically delete my data related to this location and issue a refund for the
                    data storage deposit 2 weeks after end of lease. Remember to change lease end
                    date prior to this to avoid unexpected data loss in the event of a lease
                    extension
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Container>

        <Box mt={'25px'} width={'100%'} display={'flex'}>
          <Button
            disableRipple
            sx={{ ml: 'auto', width: { xs: '100%', sm: '200px' } }}
            variant="contained"
            //onClick={handleSubmit(handleSubmitReview, handleSubmitReviewError)}
          >
            {submitSuccess ? (
              'Success'
            ) : isSubmitting ? (
              <CircularProgress size="24.5px" sx={{ color: '#FFF' }} />
            ) : (
              'Save & Continue'
            )}
          </Button>
        </Box>
      </FormControl>
    </Container>
  );
});

export default AddPropertyModalBody;
