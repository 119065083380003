import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AUTH_STATE, useAuthStore } from '../../../state/auth';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';

const FederatedSignin = () => {
  const n = useNavigate();
  const { pathname } = useLocation();
  const { authState, completeThirdPartySignon } = useAuthStore(
    ({ authState, completeThirdPartySignon }) => ({
      authState,
      completeThirdPartySignon,
    })
  );

  useEffect(() => {
    if (authState === AUTH_STATE.AUTHENTICATED) {
      n('/');
    }
  }, [authState]);

  useEffect(() => {
    if (pathname.includes('signin')) {
      completeThirdPartySignon().catch((e) => {
        Sentry.captureException(e);
        n('/logout');
      });
    } else {
      n('/login');
    }
  }, []);

  return (
    <Box width="100%" height="100%" display="flex">
      <LoadingSpinner />
    </Box>
  );
};

export default FederatedSignin;
