import {
  IconDefinition,
  faCalendarXmark,
  faCheck,
  faCircleExclamation,
  faFileContract,
  faPhotoFilm,
  faTriangleExclamation,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useAppStateStore } from '../../state/app';
import LoadingSpinner from '../../components/LoadingSpinner';
import { FunctionComponent, ReactNode } from 'react';
import { Property } from '../../providers/rentalrecords/types';
import { useModalStateStore } from '../../state/modals';

const chipSortOrder = ['error', 'warning', 'success', 'info'];

const PropertyChips: FunctionComponent<{ property: Property }> = ({ property }) => {
  const chips: {
    label: string;
    icon: IconDefinition;
    color: 'info' | 'error' | 'warning' | 'success';
  }[] = [];

  if (property.license?.status === 'ACTIVE') {
    chips.push({
      label: 'Active License',
      icon: faCheck,
      color: 'success',
    });
  } else if (property.license?.status === 'TRIAL') {
    chips.push({
      label: 'Trial: No License',
      icon: faTriangleExclamation,
      color: 'warning',
    });
  } else {
    chips.push({
      label: 'No License',
      icon: faCircleExclamation,
      color: 'error',
    });
  }

  const remainingLeaseDays =
    (new Date(property.leaseEnd).getTime() -
      new Date(new Date().toLocaleDateString('en-CA')).getTime()) /
    (1000 * 3600 * 24);

  if (remainingLeaseDays < 1) {
    chips.push({
      label: 'Past End of Lease Date',
      icon: faCalendarXmark,
      color: 'error',
    });
  } else if (remainingLeaseDays <= 7) {
    chips.push({
      label: 'Less Than a Week to End of Lease',
      icon: faCalendarXmark,
      color: 'warning',
    });
  }

  if (property.users.length > 1) {
    chips.push({
      label: 'Shared',
      icon: faUsers,
      color: 'info',
    });
  }

  return (
    <List sx={{ gap: '5px', display: 'flex', flexWrap: 'wrap' }}>
      {chips
        .sort((a, b) => chipSortOrder.indexOf(a.color) - chipSortOrder.indexOf(b.color))
        .map(({ label, icon, color }) => (
          <ListItem key={`chip-${label.replace(/\s/g, '')}`} sx={{ p: 0, width: 'fit-content' }}>
            <Chip
              icon={<FontAwesomeIcon style={{ paddingLeft: '2px' }} size="1x" icon={icon} />}
              label={label}
              color={color}
              size="small"
            />
          </ListItem>
        ))}
    </List>
  );
};

const PropertyAlerts: FunctionComponent<{ property: Property }> = ({ property: { license } }) => {
  const alerts: ReactNode[] = [];

  if (license?.status === 'TRIAL' && license.trialEnd) {
    const remainingLicenseDays =
      (new Date(new Date(license.trialEnd).toLocaleDateString('en-CA')).getTime() -
        new Date(new Date().toLocaleDateString('en-CA')).getTime()) /
      (1000 * 3600 * 24);

    alerts.push(
      <Alert key={'dashboard-alert-trial-expiring'} severity="warning">
        This property does not have an active license and access will be restricted in{' '}
        {remainingLicenseDays} day{remainingLicenseDays === 1 ? '' : 's'}.
      </Alert>
    );
  } else if (license?.status === 'TRIAL') {
    alerts.push(
      <Alert key={'dashboard-alert-trial-expiring'} severity="warning">
        This property does not have an active license and access will be restricted soon.
      </Alert>
    );
  }

  return alerts;
};

const Dashboard = () => {
  const { openAddPropertyModal } = useModalStateStore(({ openAddPropertyModal }) => ({
    openAddPropertyModal,
  }));
  const { properties, currentProperty } = useAppStateStore(({ properties, currentProperty }) => ({
    properties,
    currentProperty,
  }));

  if (properties && properties.length === 0)
    return (
      <Box width="100%" height="100%" display="flex">
        <Box m={'auto'}>
          <Button
            onClick={openAddPropertyModal}
            variant="contained"
            sx={{
              padding: '10px',
              aspectRatio: '1',
              borderRadius: '50%',
              border: '12px solid rgb(255 255 255 / 26%)',
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
              <Box display={'flex'} gap={'5px'} m={'auto'}>
                <FontAwesomeIcon size={'3x'} icon={faFileContract} />
                <FontAwesomeIcon size={'3x'} icon={faPhotoFilm} />
              </Box>
              <Typography variant="h2">Get Started</Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    );

  if (!currentProperty)
    return (
      <Box width="100%" height="100%" display="flex">
        <LoadingSpinner />
      </Box>
    );

  return (
    <>
      <PropertyAlerts property={currentProperty} />
      <Container sx={{ py: { xs: '16px', sm: '24px' }, width: '100%', height: '100%' }}>
        <Typography variant="h1">
          {currentProperty.displayName || currentProperty.address.address}
        </Typography>
        <PropertyChips property={currentProperty} />
        <Grid container>
          <Grid item></Grid>
        </Grid>
      </Container>
      ,
    </>
  );
};

export default Dashboard;
