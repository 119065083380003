import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { AuthWrapper } from '.';
import { useState } from 'react';
import { useAuthStore } from '../../../state/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

const ForgotPassword = () => {
  const n = useNavigate();
  const { forgotPassword, forgotPasswordSubmit } = useAuthStore(
    ({ forgotPassword, forgotPasswordSubmit }) => ({
      forgotPassword,
      forgotPasswordSubmit,
    })
  );

  const [email, setEmail] = useState<string>('');

  const [enteringCode, setEnteringCode] = useState<boolean>(false);

  const [verificationCode, setVerificationCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  async function handleForgotPasswordSubmit() {
    if (email) {
      toast.info('Sending code', {
        autoClose: false,
        toastId: 'handleForgotPasswordSubmit',
      });
      forgotPassword(email)
        .then(() => {
          toast.update('handleForgotPasswordSubmit', {
            type: 'success',
            render: `Reset code sent to ${email}`,
            autoClose: 4000,
          });
          setEnteringCode(true);
        })
        .catch((e) => {
          Sentry.captureException(e);
          toast.update('handleForgotPasswordSubmit', {
            type: 'error',
            render: `Error sending code to ${email}`,
            autoClose: 4000,
          });
        });
    }
  }

  async function handleForgotPasswordCompleteSubmit() {
    if (verificationCode && newPassword && /^[\S]+.*[\S]+$/.test(newPassword)) {
      toast.info('Submitting', {
        autoClose: false,
        toastId: 'handleForgotPasswordCompleteSubmit',
      });
      forgotPasswordSubmit(email, verificationCode, newPassword)
        .then(() => {
          toast.update('handleForgotPasswordCompleteSubmit', {
            type: 'success',
            render: `Password successfully reset`,
            autoClose: 4000,
          });
          n('/login');
        })
        .catch((e) => {
          Sentry.captureException(e);

          if (e.code === 'CodeMismatchException') {
            toast.update('handleForgotPasswordCompleteSubmit', {
              type: 'error',
              render: e.message || 'Incorrect code',
              autoClose: 4000,
            });
          } else if (e.code === 'InvalidPasswordException') {
            toast.update('handleForgotPasswordCompleteSubmit', {
              type: 'error',
              render: e.message || 'Password does not meet requirements',
              autoClose: 4000,
            });
          } else {
            console.error(e);
            toast.update('handleForgotPasswordCompleteSubmit', {
              type: 'error',
              render: e.message || `There was an error updating your password. Please try again.`,
              autoClose: 4000,
            });
          }
        });
    }
  }

  if (enteringCode)
    return (
      <Box padding={{ xs: '1rem', sm: '3rem' }}>
        <Box marginBottom={'1.5rem'} display={'flex'} gap={'5px'}>
          <Typography variant="h1" flexGrow={1}>
            New Password
          </Typography>
        </Box>

        <Container
          disableGutters
          component={'form'}
          sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          <TextField
            value={verificationCode}
            onChange={({ target: { value } }) => setVerificationCode(value.trim())}
            fullWidth
            label="Code"
            size="small"
          />

          <TextField
            value={newPassword}
            onChange={({ target: { value } }) => setNewPassword(value)}
            fullWidth
            label="New Password"
            size="small"
            autoComplete="new-password"
          />

          <Button
            disabled={!verificationCode || !/^[\S]+.*[\S]+$/.test(newPassword)}
            onClick={handleForgotPasswordCompleteSubmit}
            variant="contained"
            sx={{ textTransform: 'none' }}
          >
            Submit New Password
          </Button>
        </Container>
      </Box>
    );

  return (
    <Box padding={{ xs: '1rem', sm: '3rem' }}>
      <Box marginBottom={'1.5rem'} display={'flex'} gap={'5px'}>
        <Typography variant="h1" flexGrow={1}>
          Forgot Password
        </Typography>
      </Box>

      <Container
        disableGutters
        component={'form'}
        sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      >
        <TextField
          value={email}
          onChange={({ target: { value } }) => setEmail(value.trim())}
          fullWidth
          label="Email"
          size="small"
          autoComplete="username"
        />

        <Button
          disabled={!email}
          onClick={handleForgotPasswordSubmit}
          variant="contained"
          sx={{ textTransform: 'none' }}
        >
          Send Reset Code
        </Button>
      </Container>
    </Box>
  );
};

export default () => (
  <AuthWrapper>
    <ForgotPassword />
  </AuthWrapper>
);
