import {
  Autocomplete,
  AutocompleteInputChangeReason,
  TextField,
  useFormControl,
} from '@mui/material';
import { debounce } from 'lodash';
import * as Sentry from '@sentry/react';
import { FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';

const LandlordAutocomplete: FunctionComponent<{
  label: string;
  disabled?: boolean;
  error?: FieldError;
  onBlur: (...args: unknown[]) => void;
  onChange: (newVal: string) => void;
  country?: string;
  state?: string;
}> = ({ label, disabled, error, onBlur, onChange: onChangeProp, country, state }) => {
  const formControl = useFormControl();
  const [currentInput, setCurrentInput] = useState('');
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  const debounceFetch = useMemo(
    () =>
      debounce(
        () => {
          if (country && state) {
          }
        },
        500,
        {
          leading: false,
          trailing: true,
        }
      ),
    []
  );

  useEffect(() => {
    return () => {
      debounceFetch.cancel();
    };
  }, []);

  useEffect(() => {
    debounceFetch.cancel();
    debounceFetch();
  }, [currentInput]);

  const onInputChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason !== 'input') return;

    if (
      newValue
        .trim()
        .match(
          /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
        )
    ) {
      setCurrentInput('');
      onChangeProp('');
      toast.warn('Manually entered GUIDs are not valid', { autoClose: 2000 });
      Sentry.captureEvent({
        message: 'UUID entered for landlord name',
        level: 'warning',
        extra: {
          value: newValue,
        },
      });
      return;
    }

    setCurrentInput(newValue);
    onChangeProp(newValue);
  };

  const onSelect = (
    _: SyntheticEvent<Element, Event>,
    newValue:
      | string
      | {
          label: string;
          value: string;
        }
      | null
  ) => {
    debounceFetch.cancel();

    if (newValue && typeof newValue !== 'string') {
      onChangeProp(newValue?.value);
    }
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      blurOnSelect
      multiple={false}
      size="small"
      onChange={onSelect}
      onInputChange={onInputChange}
      onBlur={onBlur}
      disabled={disabled || formControl?.disabled}
      options={options}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          helperText={error?.message}
          error={!!error}
          label={label}
          {...params}
        />
      )}
      sx={{ width: '100%', minWidth: { sm: '200px' } }}
    />
  );
};

export default LandlordAutocomplete;
