import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ModalState {
  contactUsOpen: boolean;
  openContactUsModal: () => void;
  closeContactUsModal: () => void;

  addPropertyOpen: boolean;
  openAddPropertyModal: () => void;
  closeAddPropertyModal: () => void;
}

export const useModalStateStore = create<ModalState>()(
  devtools((set) => ({
    contactUsOpen: false,
    openContactUsModal: () => set({ contactUsOpen: true }),
    closeContactUsModal: () => set({ contactUsOpen: false }),

    addPropertyOpen: false,
    openAddPropertyModal: () => set({ addPropertyOpen: true }),
    closeAddPropertyModal: () => set({ addPropertyOpen: false }),
  }))
);
