// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  background-color: rgba(228, 228, 245, 0.8);
}

a,
a:active {
  color: #526ebf;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  div.mobile-console {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,YAAY;EACZ,aAAa;EACb,0CAA0C;AAC5C;;AAEA;;EAEE,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["body {\n  padding: 0;\n  margin: 0 auto;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(228, 228, 245, 0.8);\n}\n\na,\na:active {\n  color: #526ebf;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n@media only screen and (min-width: 600px) {\n  div.mobile-console {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
