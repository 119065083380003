import * as Sentry from '@sentry/react';
import axios from 'axios';
import { User } from './types';
import { Auth } from 'aws-amplify';
import { useAuthStore } from '../../state/auth';

async function getAuthHeader(): Promise<{
  Authorization: string;
}> {
  return {
    Authorization: `Bearer ${await Auth.currentSession()
      .then((session) => session.getAccessToken().getJwtToken())
      .catch((e) => {
        Sentry.captureException(e);
        useAuthStore.getState().logout();
      })}`,
  };
}

const landlordSpotlightInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
});

/**
 * @function getUserData
 *
 * @returns { Prmoise<User> }
 */
export async function getUserData(): Promise<User> {
  return landlordSpotlightInstance
    .request<User>({
      url: 'currentuser',
      method: 'get',
      headers: { ...(await getAuthHeader()) },
    })
    .then(({ data }) => data);
}
