import { useFormControl } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotsComponentsProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enCA from 'date-fns/locale/en-CA';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { merge } from 'lodash';

const FormDatePicker = <R extends FieldValues>({
  name,
  control,
  disabled,
  slotProps,
  ...datePickerProps
}: {
  name: Path<R>;
  control: Control<R>;
} & DatePickerProps<Date>) => {
  const formControl = useFormControl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enCA}>
          <DatePicker
            onChange={onChange}
            onClose={onBlur}
            value={value}
            sx={{
              width: '100%',
            }}
            disabled={disabled || formControl?.disabled}
            slotProps={merge<
              DatePickerSlotsComponentsProps<Date>,
              DatePickerSlotsComponentsProps<Date>
            >(slotProps || {}, {
              textField: {
                error: !!error,
                helperText: error?.message ? error.message : undefined,
              },
            })}
            {...datePickerProps}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default FormDatePicker;
