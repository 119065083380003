import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

const FormCheckbox = <R extends FieldValues>({
  name,
  control,
  disableRipple = true,
  ...checkboxProps
}: {
  name: Path<R>;
  control: Control<R>;
} & CheckboxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <FormControlLabel
          label={!!error ? '*' : ''}
          labelPlacement="end"
          style={{ color: '#d32f2f' }}
          control={
            <Checkbox
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              disableRipple={disableRipple}
              sx={{ color: !!error ? '#d32f2f' : undefined }}
              {...checkboxProps}
            />
          }
        />
      )}
    />
  );
};

export default FormCheckbox;
