import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, Container, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

const ImageUploadPreviewer: FunctionComponent<{
  onChange: (files: File[]) => void;
  disabled?: boolean;
}> = ({ onChange, disabled }) => {
  const [files, setFiles] = useState<(File & { preview: string })[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
      'image/gif': [],
    },
    maxFiles: 3,
    maxSize: 10485760,
    minSize: 50000,
    multiple: false,
    disabled: disabled || files.length >= 3,
    onDropRejected: (rejectedFiles) => {
      for (const rejectedFile of rejectedFiles) {
        toast.error(
          () => [
            <Typography>File: {rejectedFile.file.name}</Typography>,
            <Typography>Error: {rejectedFile.errors[0].message}</Typography>,
          ],
          { autoClose: 1500 }
        );
      }
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => {
        const filesToAdd: File[] = [];

        for (const newFile of acceptedFiles) {
          if (prev.map(({ name }) => name).includes(newFile.name)) {
            toast.error(`File ${newFile.name} was already selected`, {
              autoClose: 2000,
              pauseOnFocusLoss: false,
            });
          } else {
            filesToAdd.push(newFile);
          }
        }

        return [
          ...prev,
          ...filesToAdd.map((file: File) =>
            Object.assign(file, {
              preview: (window.URL || window.webkitURL || URL).createObjectURL(file),
            })
          ),
        ];
      });
    },
  });

  useEffect(() => {
    onChange(files);
  }, [files]);

  const removeImage = (fileName: string) => {
    setFiles((prev) => {
      const newFilesArr = cloneDeep(prev);

      newFilesArr
        .splice(
          prev.findIndex(({ name }) => name === fileName),
          1
        )
        .forEach(({ preview }) => URL.revokeObjectURL(preview));

      return newFilesArr;
    });
  };

  const thumbs = files.map((file) => (
    <Badge
      sx={{ margin: 'auto', height: 'auto' }}
      key={file.name}
      badgeContent={
        <FontAwesomeIcon
          onClick={() => {
            removeImage(file.name);
          }}
          cursor={'pointer'}
          size="2x"
          icon={faSquareXmark}
          color="#F00"
        />
      }
    >
      <Box
        component="div"
        sx={{
          display: 'inline-flex',
          borderRadius: 2,
          overflow: 'hidden',
          border: '1px solid #eaeaea',
          margin: 'auto',
          maxWidth: '100px',
          width: '100%',
          height: 'fit-content',
          padding: 0,
          boxSizing: 'border-box',
          cursor: 'default',
        }}
        key={file.name}
      >
        <div
          style={{
            display: 'flex',
            minWidth: 0,
            overflow: 'hidden',
          }}
        >
          <img
            src={file.preview}
            style={{
              display: 'block',
              width: '100%',
              height: 'fit-content',
            }}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </Box>
    </Badge>
  ));

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: 'rgb(242 229 253)',
        textAlign: 'center',
        borderRadius: '4px',
        padding: '30px 0',
        display: 'flex',
        border: '2px dashed #bcbcbc',
        cursor: 'pointer',
      }}
      {...getRootProps({ className: 'imageUploader' })}
    >
      <Box mx={'auto'} display={'flex'} flexDirection={'column'} gap={'20px'}>
        <input disabled={files.length >= 3} {...getInputProps()} />

        <Typography color={files.length >= 3 ? '#969696' : 'inherit'}>
          Drag 'n' drop some files here, or click to select files
        </Typography>

        {files.length ? (
          <Box component="aside" display="flex">
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
              padding={0}
              mx={'auto'}
              display={'flex'}
              gap={'10px'}
              flexWrap={'wrap'}
            >
              {thumbs}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Container>
  );
};

export default ImageUploadPreviewer;
