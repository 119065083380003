import { Modal } from '@mui/material';
import { FunctionComponent } from 'react';
import { useModalStateStore } from '../../state/modals';
import ContactUsModalBody from './ContactUsModalBody';

const ContactUsModal: FunctionComponent<Record<string, never>> = ({}) => {
  const { contactUsOpen, closeContactUsModal } = useModalStateStore(
    ({ contactUsOpen, closeContactUsModal }) => ({
      contactUsOpen,
      closeContactUsModal,
    })
  );

  return (
    <>
      <Modal open={contactUsOpen} onClose={closeContactUsModal}>
        <ContactUsModalBody />
      </Modal>
    </>
  );
};

export default ContactUsModal;
